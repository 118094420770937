import React from "react";
import styles from "./index.module.css";

export default () =>
    <React.Fragment>
        <div className={styles.container}>
            <div className={styles.content}>
                <div className={styles.text}>让社区生活更简单</div>
                <span className={styles.contact}>联系我们 : 400-811-6080</span>
            </div>
        </div>
        <div className={styles.containerDown}>
            <div className={styles.content}>
                <div className={styles.detail}>
                    <div className={styles.title}>Copyright &copy; 2018 北京社区半径信息技术有限公司 京ICP备15003890号-1 </div>
                    <a className={styles.url} href="https://lehome-storage-public.oss-cn-beijing.aliyuncs.com/property/web_logo/zzdx.jpg" target="_blank">增值电信业务经营许可证京B2-20180440</a>
                    <div className={styles.title}>公司地址：北京市海淀区中关村大街46号院 阿里巴巴创新中心</div>
                </div>
            </div>
        </div>
    </React.Fragment>;