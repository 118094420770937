import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styles from "./one.module.css";

export default () =>
    <div className={styles.container} id="learn-more">
        <div className={styles.section}>
            <div className={styles.title}>智社区基础平台</div>
            <div className={styles.prompt}>基于阿里云平台服务支撑，安全稳定的基础平台，支撑满足各个平台应用</div>
            <Row>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/xiaoqudangan.png")} />
                        <div className={styles.blockTitle}>小区档案管理</div>
                        <div className={styles.blockDescription}>基础完善的小区信息、房屋信息档案</div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/yewuzhuizong.png")} />
                        <div className={styles.blockTitle}>业务追踪管理</div>
                        <div className={styles.blockDescription}>员工待办工作分派提醒，业务情况可视化全面跟踪</div>
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/juminshuju.png")} />
                        <div className={styles.blockTitle}>居民数据管理</div>
                        <div className={styles.blockDescription}>实时跟踪居民平台数据，多维度记录居民标签</div>
                    </div>
                </Col>
            </Row>
        </div>
    </div>