import React from "react";
import { Footer } from "../../../home/smart-views";
import styles from "./index.module.css";
import { NTX_SERVICE_URI } from "../../../../config";
import { Row, Col } from "antd";

interface IState {
    contentList: any[],
    advList: any[],
}

export default class extends React.Component<any, IState> {
    public constructor(props) {
        super(props);
    }

    public state: IState = {
        contentList: [
            {
                title: '数据化运维',
                content: '设备间环境、设备状态实时检测；设备运维执行情况一目了然；告警消息自动触发、实时提醒；设备总体健康度智能计算',
                items: [
                    { title: '全景检测', icon: require('../../../../static/images/smart/nxt-management/icon_1.png') },
                    { title: '运维质量分析', icon: require('../../../../static/images/smart/nxt-management/icon_2.png') },
                    { title: '故障告警', icon: require('../../../../static/images/smart/nxt-management/icon_3.png') },
                    { title: '智能综合分析', icon: require('../../../../static/images/smart/nxt-management/icon_4.png') }
                ],
                pic: require('../../../../static/images/smart/nxt-management/bg_1.png')
            },
            {
                title: '规范化管理',
                content: '业务流程与管理模式符合中国物业管理协会设备设施技术委员会的技术标准，也满足实际管理需求',
                items: [
                    { title: '运行安全', icon: require('../../../../static/images/smart/nxt-management/icon_8.png') },
                    { title: '监测标准', icon: require('../../../../static/images/smart/nxt-management/icon_15.png') },
                    { title: '运维流程', icon: require('../../../../static/images/smart/nxt-management/icon_16.png') },
                    { title: '应用科技', icon: require('../../../../static/images/smart/nxt-management/icon_17.png') }
                ],
                pic: require('../../../../static/images/smart/nxt-management/bg_2.png')
            },
            {
                title: '全生命周期覆盖',
                content: '一站式完整记录设备基本信息、启停状态、实时运行数据、操作日志、告警消息、维保记录、维保合同、使用说明等，覆盖设备设施管理全生命周期，实现全维度电子档案。',
                items: [
                    { title: '设备台帐管理', icon: require('../../../../static/images/smart/nxt-management/icon_7.png') },
                    { title: '使用过程记录', icon: require('../../../../static/images/smart/nxt-management/icon_5.png') },
                    { title: '电子档案管理', icon: require('../../../../static/images/smart/nxt-management/icon_6.png') }
                ],
                pic: require('../../../../static/images/smart/nxt-management/bg_3.png')
            },
            {
                title: '自动化运维',
                content: '通过硬件传感器+信息化系统+标准化参数设置实现设备设施的全自动化运维，减少大量的现场人工抄表、值班等工作，配合告警参数设置和告警预案设置，实现故障自动告警与快速预案处理。',
                items: [
                    { title: '实时监测', icon: require('../../../../static/images/smart/nxt-management/icon_11.png') },
                    { title: '自动告警', icon: require('../../../../static/images/smart/nxt-management/icon_12.png') },
                    { title: '风险预案', icon: require('../../../../static/images/smart/nxt-management/icon_13.png') }
                ],
                pic: require('../../../../static/images/smart/nxt-management/bg_4.png')
            },
            {
                title: '稳定运行保障',
                content: '通过智能巡检、故障报修、设备保养等一整套安全防护措施，实现设备设施的稳定运行',
                items: [
                    { title: '全景检测', icon: require('../../../../static/images/smart/nxt-management/icon_8.png') },
                    { title: '故障告警', icon: require('../../../../static/images/smart/nxt-management/icon_12.png') },
                    { title: '运维分析', icon: require('../../../../static/images/smart/nxt-management/icon_10.png') }
                ],
                pic: require('../../../../static/images/smart/nxt-management/bg_5.png')
            }
        ],
        advList: [
            { title: '数据化运维', icon: require('../../../../static/images/smart/nxt-management/adv_1.png') },
            { title: '规范化管理', icon: require('../../../../static/images/smart/nxt-management/adv_2.png') },
            { title: '全生命周期覆盖', icon: require('../../../../static/images/smart/nxt-management/adv_3.png') },
            { title: '稳定运行保障', icon: require('../../../../static/images/smart/nxt-management/adv_4.png') },
            { title: '自动化运维', icon: require('../../../../static/images/smart/nxt-management/adv_5.png') }
        ],
    }

    public componentDidMount() {

    }

    public bannerRender() {
        return <div className={styles.banner}>
            <div className={styles.content}>
                <img src={require("../../../../static/images/smart/nxt-management/nxt-logo.png")} />
                <input type="button" value="立即登录" className={styles.button} onClick={() => window.location.href = `${NTX_SERVICE_URI}`} />
                <div style={{ color: '#fff', fontSize: '18px', marginTop: '48px', fontFamily: 'PingFangSC-Regular' }}>五大产品优势</div>
            </div>
        </div>
    }

    public advRender() {
        let { advList } = this.state;
        if ((/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent))) {
            return <div className={styles.advContent} style={{ width: '80%', marginLeft: '10%', justifyContent: 'center' }}>
                <Row type="flex" justify="space-around" align="middle">
                    {advList.map((n, i) => <Col span={2.5}>
                        <div className={styles.oneAdv} key={i} style={{ marginRight: 0, width: '150px' }}>
                            <img src={n.icon} style={{ width: '70px', height: '70px' }}></img>
                            <div className={styles.title}>{n.title}</div>
                        </div>
                    </Col>)}
                </Row>
            </div>
        } else {
            return <div className={styles.advContent} style={{ paddingLeft: 'calc(50% - 560px)', width: '100%', marginLeft: 0, background: 'transparent', boxShadow: '0px 0px 0px rgba(0,0,0,0)' }}>
                <div className={styles.adv}>
                    {
                        advList.map((n, i) => {
                            return <div className={styles.oneAdv} key={i}>
                                <img src={n.icon} style={{ width: '70px', height: '70px' }}></img>
                                <div className={styles.title}>{n.title}</div>
                            </div>
                        })
                    }
                </div>
            </div>
        }
    }

    public contentRender() {
        let { contentList } = this.state;
        if ((/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent))) {
            return <div className={styles.containerMain} style={{ flexDirection: 'column', justifyContent: 'center' }}>
                {
                    contentList.map((n, i) => {
                        return <div className={styles.oneContent_iphone} key={i} >
                            <div className={styles.textContent} style={{ alignItems: 'center' }}>
                                <div className={styles.title}>{n.title}</div>
                                <div className={styles.line}></div>
                                <div className={styles.text}>{n.content}</div>
                                <div className={styles.iconContent}>
                                    {
                                        n.items.map((m, k) => {
                                            return <div style={{ display: 'flex', alignItems: 'center', marginBottom: '14px', width: '50%', justifyContent: 'center' }} key={k}>
                                                <img src={m.icon} style={{ width: '26px', height: '26px', marginRight: '11px' }} />
                                                <span style={{ color: '#666', fontSize: '14px' }}>{m.title}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                            <img src={n.pic} style={{ width: '100%', height: 'auto' }} />
                        </div>
                    })
                }
            </div>
        } else {
            return <div className={styles.containerMain} style={{ paddingLeft: 'calc(50% - 560px)' }}>
                <div className={styles.content}>
                    {
                        contentList.map((n, i) => {
                            return <div className={styles.oneContent} key={i} style={{ flexDirection: i % 2 !== 0 ? 'row-reverse' : 'row' }}>
                                <div className={styles.textContent}>
                                    <div className={styles.title}>{n.title}</div>
                                    <div className={styles.line}></div>
                                    <div className={styles.text}>{n.content}</div>
                                    <div className={styles.iconContent}>
                                        {
                                            n.items.map((m, k) => {
                                                return <div style={{ display: 'flex', alignItems: 'center', marginRight: '30px', marginBottom: '14px' }} key={k}>
                                                    <img src={m.icon} style={{ width: '26px', height: '26px', marginRight: '11px' }} />
                                                    <span style={{ color: '#666', fontSize: '14px' }}>{m.title}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                                <img src={n.pic} style={{ width: '504px', height: '300px' }} />
                            </div>
                        })
                    }
                </div>
            </div>
        }

    }

    public footerRender() {
        if ((/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent))) {
            return <div className={styles.containerDown}>
                <div className={styles.content} style={{ width: '100%' }}>
                    <div className={styles.detail}>
                        <div className={styles.title}>Copyright &copy; 2019 北京社区半径信息技术有限公司</div>
                        <div className={styles.title}>京ICP备15003890号-1</div>
                        <div className={styles.title}>公司地址：北京市海淀区中关村大街46号院 阿里巴巴创新中心</div>
                    </div>
                </div>
            </div>
        } else {
            return <div className={styles.containerDown}>
                <div className={styles.content}>
                    <div className={styles.detail}>
                        <div className={styles.title}>Copyright &copy; 2019 北京社区半径信息技术有限公司 京ICP备15003890号-1 </div>
                        <div className={styles.title}>公司地址：北京市海淀区中关村大街46号院 阿里巴巴创新中心</div>
                    </div>
                </div>
            </div>
        }
    }

    public onClickMenu = (route) => evt => {
        evt.preventDefault();
        evt.stopPropagation();
        this.props.history.push(route);
    }

    public backRender() {
        return <div className={styles.back} onClick={this.onClickMenu("/")} 
                style={{ top: (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) ? '21px' : '41px', 
                         left: (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) ? '21px' : '41px' 
                }}>
            <img src={require('../../../../static/images/smart/nxt-management/fill.png')}></img>
        </div>
    }

    public render() {
        return <div className={styles.container} style={{ minWidth: (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) ? '100px' : '1120px' }}>
            {this.bannerRender()}
            {this.advRender()}
            {this.contentRender()}
            {this.footerRender()}
            {this.backRender()}
        </div>
    }
}