import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styles from "./four.module.css";

export default (props: { contractMe() }) =>
    <div className={styles.container}>
        <div className={styles.section}>
            <div className={styles.title}>互联网+社区平台</div>
            <div className={styles.prompt}>构建互联网的社区居民平台，挖掘智慧社区服务的价值金矿</div>
            <Row>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/shequjingyingfuwu.png")} />
                        <div className={styles.blockTitle}>社区经营服务</div>
                        <div className={styles.blockDescription}>在社区内开展社区的电商、社区的服务等满足业主所需的多种经营</div>
                        <input type="button" className={styles.button} value="联系我们" onClick={props.contractMe} />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/shequlitiyingxiao.png")} />
                        <div className={styles.blockTitle}>社区立体营销</div>
                        <div className={styles.blockDescription}>通过业主平台和小区线下广告资源整合，对社区的立体营销推广方案</div>
                        <input type="button" className={styles.button} value="联系我们" onClick={props.contractMe} />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/wuyezichanyunying.png")} />
                        <div className={styles.blockTitle}>物业资产运营</div>
                        <div className={styles.blockDescription}>管理物业资产进行房屋租赁、托管、交易等，有效开展资产运营</div>
                        <input type="button" className={styles.button} value="联系我们" onClick={props.contractMe} />
                    </div>
                </Col>
            </Row>
        </div>
    </div>