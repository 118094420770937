import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styles from "./three.module.css";

export default (props: { contractMe() }) =>
    <div className={styles.container}>
        <div className={styles.section}>
            <div className={styles.title}>智能物联平台</div>
            <div className={styles.prompt}>连接社区智能硬件与传感器，提高管理效率，让社区“万物互联”</div>
            <Row>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/wulianwangmenjin.png")} />
                        <div className={styles.blockTitle}>物联网门禁</div>
                        <div className={styles.blockDescription}>支持手机开门，远程开门的物联网门禁，智慧社区的标配产品</div>
                        <input type="button" className={styles.button} value="联系我们" onClick={props.contractMe} />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/wurencheliangdaozha.png")} />
                        <div className={styles.blockTitle}>无人车辆道闸</div>
                        <div className={styles.blockDescription}>无人值守的智能车辆道闸系统，人工智能与社区管理的碰撞融合</div>
                        <input type="button" className={styles.button} value="联系我们" onClick={props.contractMe}  />
                    </div>
                </Col>
                <Col span={8}>
                    <div className={styles.block}>
                        <img src={require("../../../../static/images/smart/keshiduijiang.png")} />
                        <div className={styles.blockTitle}>可视对讲</div>
                        <div className={styles.blockDescription}>创新革命型的物联网可视对讲产品，开创行业新标杆</div>
                        <input type="button" className={styles.button} value="联系我们" onClick={props.contractMe}  />
                    </div>
                </Col>
            </Row>
        </div>
    </div>