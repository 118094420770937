import * as React from "react";
import { Banner, Header, IndexSections } from "./smart-views";
import { CLIENT } from "../../config";
import { Modal } from "antd";

export default class extends React.Component<any, {}> {
  public render() {
    return <SmartHome />
  }
}

class SmartHome extends React.Component<any, { showContractMe: boolean; }> {

  public state = { showContractMe: false }

  public componentDidMount() {
    document.title = "社区半径-中国领先的智慧社区解决方案提供商";
  }
  public render() {
    return <>
      <Header theme="transparent" />
      <Banner contractMe={this.contractMe} />
      <IndexSections.One />
      <IndexSections.Two />
      <IndexSections.Three contractMe={this.contractMe} />
      <IndexSections.Four contractMe={this.contractMe} />
      {
        !!this.state.showContractMe && 
        <Modal 
          width={300}
          visible 
          footer={null}
          closable={false} 
          onCancel={() => this.setState({showContractMe: false})} >
            <div>
              <div style={{
                  textAlign: "center", 
                  lineHeight: "28px",
                  marginBottom: 10,
              }}>如果您需要进一步了解该系统</div>
              <div style={{
                  textAlign: "center", 
                  lineHeight: "28px",
              }}>请致电：400-811-6080</div>
              <div 
                  onClick={() => this.setState({showContractMe: false})}
                  style={{
                    textAlign: "center",
                    borderTop: "1px solid #e3e3e3",
                    margin: "24px -24px 0",
                    paddingTop: "10px",
                    marginBottom: "-10px",
                    fontWeight: 400,
                    cursor: "pointer",
                    color: "#42a1f4",
                    letterSpacing: "4px",
                  }}>
                <span>确定</span>
              </div>
            </div>
        </Modal>
    }
    </>;
  }

  private contractMe = () => this.setState({showContractMe: true})

}