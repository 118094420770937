import Home from "../pages/home";
import NxtManagement from "../pages/product/saas/nxt-management";
import ChargeManagement from "../pages/product/saas/charge-management";
import CustomerManagement from "../pages/product/saas/customer-service";
import FacilityManagement from "../pages/product/saas/facility-management";

export default [
    { path: "/", component: Home },
    { path: "/product/saas/nxt-service", component: NxtManagement },
    { path: "/product/saas/charge-management", component: ChargeManagement },
    { path: "/product/saas/customer-service", component: CustomerManagement },
    { path: "/product/saas/facility-management", component: FacilityManagement }
]