import React from "react";
import { withRouter } from "react-router-dom";
import Icon from "antd/lib/icon";
import styles from "./index.module.css";

export default withRouter(
    class extends React.Component<any, {}> {
        public onClickMenu = (route) => evt => {
            evt.preventDefault();
            evt.stopPropagation();
            this.props.history.push(route);
        }
        public render() {
            const { theme = "transparent", history } = this.props;
            const isTrans = theme === "transparent";

            return <div className={isTrans ? styles.containerTrans : styles.container}>
                <div className={styles.header}>
                    <img src={isTrans ? require("../../../../static/images/smart/logo.png") : require("../../../../static/images/smart/logo-blue.png")} className={styles.logo} />
                    <ul className={styles.menu}>
                        <li onClick={() => history.push("/")}>首页</li>
                        <li className={styles.dropdown} onClick={() => history.push("/product/saas")}>产品介绍
                            <span className={styles['dropdown-icon']} style={{display: "inline-block", marginLeft: '5px'}}><Icon type="down" /></span>
                            <div className={styles.dropdownFill}></div>
                            <div className={styles.dropdownMenu}>
                                <div className={styles.menuBlock}>
                                    <ul className={styles.submenus}>
                                        <li className={styles.submenu} onClick={this.onClickMenu("/product/saas/customer-service")}>
                                            <img src={require("../../../../static/images/smart/icon_gongdan.png")} />
                                            <img src={require("../../../../static/images/smart/icon_gongdan_hover.png")} className={styles.imgHover} />
                                            <span>客服工单</span>
                                        </li>
                                        <li className={styles.submenu} onClick={this.onClickMenu("/product/saas/charge-management")}>
                                            <img src={require("../../../../static/images/smart/icon_shoufei.png")} />
                                            <img src={require("../../../../static/images/smart/icon_shoufei_hover.png")} className={styles.imgHover} />
                                            <span>物业收费</span>
                                        </li>
                                        <li className={styles.submenu} onClick={this.onClickMenu("/product/saas/facility-management")}>
                                            <img src={require("../../../../static/images/smart/icon_shebei.png")} />
                                            <img src={require("../../../../static/images/smart/icon_shebei_hover.png")} className={styles.imgHover} />
                                            <span>设备设施</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li className="hidden" onClick={this.onClickMenu("/product/saas/nxt-service")}>半径·智能效</li>
                        <li className="hidden" style={{visibility: 'hidden'}}>签约流程</li>
                        <li className="hidden" style={{visibility: 'hidden'}}>代理商入口</li>
                        <li className="hidden" style={{visibility: 'hidden'}}>客户案例</li>
                        <li className="hidden" style={{visibility: 'hidden'}}>关于我们</li>
                    </ul>
                    <input type="button" value="登录" className={styles.button} onClick={() => {
                        if(window.location.host.indexOf('dev') === -1){
                            window.open('https://portal.sqbj.com')
                        }else {
                            window.open('https://dev.portal.sqbj.com')
                        }
                    }} />
                </div>
            </div >
        }
    }
)
