import React from "react";
import { Header, Footer, ProduceBanner } from "../../../home/smart-views";
import styles from "./index.module.css";
import { CoreServices, TextLeft, TextRight, TextMiddle } from "../../section-template";

const SERVICES = [
    { title: "强化过程管控力度", icon: require("../../../../static/images/smart/customer-service/core1.png") },
    { title: "提高问题解决效率", icon: require("../../../../static/images/smart/customer-service/core2.png") },
    { title: "减少居民服务纠纷", icon: require("../../../../static/images/smart/customer-service/core3.png") },
    { title: "加强居民对于物业的了解程度", icon: require("../../../../static/images/smart/customer-service/core4.png") },
    { title: "有效提升居民满意度", icon: require("../../../../static/images/smart/customer-service/core5.png") },
    { title: "节约企业服务管理成本", icon: require("../../../../static/images/smart/customer-service/core6.png") },
];

export default class extends React.Component {
    public render() {
        return <div className={styles.container}>
            <Header theme="white" />
            <ProduceBanner />
            <CoreServices
                title='物业收费应用'
                description="全方位解决物业催缴费场景，多渠道便捷收取物业费"
                services={SERVICES}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/charge-management/feixiangbiaozhunshezhi-icon.png")}
                title="费项标准设置"
                description="支持物业费、押金、临时收费各种类型收费项目，收费标准灵活关联到每户房间，全面解决物业公司复杂场景的收费"
                img={require("../../../../static/images/smart/charge-management/feixiangbiaozhunshezhi.png")}
                theme="blue"
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/charge-management/shoufeijiaofeidanguanli-icon.png")}
                title="收费缴费单管理"
                description="支持按房间及批量创建收费单，提供物业收费、业主主动缴费等多种方式的收费、缴费单管理，提高物业收费管理水平"
                img={require("../../../../static/images/smart/charge-management/shoufeijiaofeidanguanli.png")}
                theme="gray"
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/charge-management/shoufeirenwuguanli-icon.png")}
                title="收费任务管理"
                description="小区收费任务精细化管理，支持按楼按户设置收费员，通过细化拆分每一个负责员工的收费任务，统筹管理收费目标"
                img={require("../../../../static/images/smart/charge-management/shoufeirenwuguanli.png")}
            />
            <TextRight
                icon={require("../../../../static/images/smart/charge-management/wuyeshangmencuishou-icon.png")}
                title="物业上门催收"
                description="携带智能POS进行上门物业费催收，收费账单实时查询，催费单实时打印，完美解决业主在家和不在家的各种场景"
                img={require("../../../../static/images/smart/charge-management/wuyeshangmencuishou.png")}
                theme="gray"
            />
            <TextLeft
                icon={require("../../../../static/images/smart/charge-management/disanfangjuhezhifu-icon.png")}
                title="第三方聚合支付"
                description="支持按房间及批量创建收费单，提供物业收费、业主主动缴费等多种方式的收费、缴费单管理，提高物业收费管理水平"
                img={require("../../../../static/images/smart/charge-management/disanfangjuhezhifu.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/charge-management/tongyishouyinjiesuan-icon.png")}
                title="统一收银结算"
                description="多处收银，一处结算，资金直达企业账户，对账方便快捷，费项金额精准匹配，资金来龙去脉一目了然"
                img={require("../../../../static/images/smart/charge-management/tongyishouyinjiesuan.png")}
                theme="gray"
            />
            <TextRight
                icon={require("../../../../static/images/smart/charge-management/zijintuoguananquan-icon.png")}
                title="资金托管安全"
                description="中国银联认证设备，银行资金托管，交易资金不由第三方二次清算，资金安全到账迅速，全面保障物业公司收款资金安全"
                img={require("../../../../static/images/smart/charge-management/zijintuoguananquan.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/charge-management/shoufeishujutongchou-icon.png")}
                title="收费数据统筹"
                description="收费金额、收费任务全程数据跟踪，收费员任务完成情况实时反馈，小区收费率一目了然，助力物业公司管理决策"
                img={require("../../../../static/images/smart/charge-management/shoufeishujutongchou.png")}
                theme="gray"
            />
            <Footer />
        </div>
    }
}