import React from "react";
import { withRouter } from "react-router-dom";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styles from "./two.module.css";

export default withRouter(
  class extends React.Component<any, {}> {
    public render() {
      return <div className={styles.container}>
        <div className={styles.section}>
          <div className={styles.title}>智慧物业应用平台</div>
          <div className={styles.prompt}>以移动化的应用，紧贴物业公司管理需求，帮助拥抱敏捷高效的新时代</div>
          <Row>
            <Col span={8}>
              <div className={styles.block}>
                <img src={require("../../../../static/images/smart/kefugongdan.png")} />
                <div className={styles.blockTitle}>客服工单应用</div>
                <div className={styles.blockDescription}>全服务链条跟踪，全流程记录留痕，提升业主满意度</div>
                <input type="button" className={styles.button} value="了解详情" onClick={() => this.props.history.push("/product/saas/customer-service")} />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.block}>
                <img src={require("../../../../static/images/smart/wuyeshoufei.png")} />
                <div className={styles.blockTitle}>物业收费应用</div>
                <div className={styles.blockDescription}>全方位解决物业催缴费场景，多渠道便捷收取物业费</div>
                <input type="button" className={styles.button} value="了解详情" onClick={() => this.props.history.push("/product/saas/charge-management")} />
              </div>
            </Col>
            <Col span={8}>
              <div className={styles.block}>
                <img src={require("../../../../static/images/smart/shebeisheshi.png")} />
                <div className={styles.blockTitle}>设备设施应用</div>
                <div className={styles.blockDescription}>设备维保、巡检、全生命周期管理保障设备健康运行</div>
                <input type="button" className={styles.button} value="了解详情" onClick={() => this.props.history.push("/product/saas/facility-management")} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    }
  }
)
