import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import routes from "../config/routes";
import Page404 from "./404";

export default class extends React.Component<any, {}> {
    public render() {
        return <Router>
            <Switch>
                {routes.map(route => <Route exact={true} path={route.path} component={route.component} key={route.path} />)}
                <Route path="*" component={Page404} />
            </Switch>
        </Router>
    }
}