import React from "react";
import { Header, Footer, ProduceBanner } from "../../../home/smart-views";
import styles from "./index.module.css";
import { CoreServices, TextLeft, TextRight, TextMiddle } from "../../section-template";

const SERVICES = [
    { title: "强化过程管控力度", icon: require("../../../../static/images/smart/customer-service/core1.png") },
    { title: "提高问题解决效率", icon: require("../../../../static/images/smart/customer-service/core2.png") },
    { title: "减少居民服务纠纷", icon: require("../../../../static/images/smart/customer-service/core3.png") },
    { title: "加强居民对于物业的了解程度", icon: require("../../../../static/images/smart/customer-service/core4.png") },
    { title: "有效提升居民满意度", icon: require("../../../../static/images/smart/customer-service/core5.png") },
    { title: "节约企业服务管理成本", icon: require("../../../../static/images/smart/customer-service/core6.png") },
];

export default class extends React.Component {
    public render() {
        return <div className={styles.container}>
            <Header theme="white" />
            <ProduceBanner />
            <CoreServices
                title="客服工单应用"
                description="全服务链条跟踪，全流程详备留痕，提升物业业主满意度"
                services={SERVICES}
            />
            <TextMiddle
                icon={require('../../../../static/images/smart/customer-service/xiaoquxianshanggonggao-icon.png')}
                title="小区线上公告"
                description="发布小区公告让物业公司把重要的信息传达给小区居民，打通物业-居民间的信息传播渠道，节省物业张贴成本，提高信息到达效率"
                img={require("../../../../static/images/smart/customer-service/xiaoquxianshanggonggao.png")}
                theme="blue"
            />
            <TextLeft
                icon={require("../../../../static/images/smart/customer-service/wuyetoutiaofengcai-icon.png")}
                title="物业头条风采"
                description="公开展示物业服务标准与服务内容，让业主通过平台更加理解物业服务工作，打造物业服务企业与居民间的和谐氛围"
                img={require("../../../../static/images/smart/customer-service/wuyetoutiaofengcai.png")}
                theme="gray"
            />
            <TextRight
                icon={require("../../../../static/images/smart/customer-service/wuyexunchagongdan-icon.png")}
                title="物业巡查工单"
                description="在巡查时随时通过移动App可随时进行拍照、视频等方式快速上报，移动化解决任务全流程，全面改变物业公司的工单管理方式"
                img={require("../../../../static/images/smart/customer-service/wuyexunchagongdan.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/customer-service/yezhugongdantibao-icon.png")}
                title="业主工单提报"
                description="支持App、电话、来访物业多种提报方式的工单管理，业主实时了解工单处理过程及结果，解决物业与业主之间信息不对称的问题，提升业主满意度"
                img={require("../../../../static/images/smart/customer-service/yezhugongdantibao.png")}
                theme="gray"
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/customer-service/zhinenggongdanpaidan-icon.png")}
                title="智能工单派单"
                description="客服实时收到派单提醒，支持手机智能派单，可以按照工单的类别（强弱电、水暖等）进行工程人员的分派及提醒，可以更高效的管理工单"
                img={require("../../../../static/images/smart/customer-service/zhinenggongdanpaidan.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/customer-service/gongdanzhuizongtixing-icon.png")}
                title="工单追踪提醒"
                description="灵活设置工单升级的规则，未处理及未完成的工单可以向各级管理人员上报，协助管理人员对工单进行督办，加强物业服务品质管理力度"
                img={require("../../../../static/images/smart/customer-service/gongdanzhuizongtixing.png")}
                theme="gray"
            />
            <TextLeft
                icon={require("../../../../static/images/smart/customer-service/yidonggongdanjilu-icon.png")}
                title="移动工单记录"
                description="在工单服务前后进行拍照留痕，处理完毕业主签字确认，过程记录还原当时的工作情况，减少与居民间由于服务质量等原因发生的分歧争议"
                img={require("../../../../static/images/smart/customer-service/yidonggongdanjilu.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/customer-service/gongdanshuziyibiaopan-icon.png")}
                title="工单数字仪表盘"
                description="全面展示工单业务的进展与状况，全面展示工单的分类统计，全面了解业务处理状况与业主满意度状况，辅助物业公司管理决策"
                img={require("../../../../static/images/smart/customer-service/gongdanshuziyibiaopan.png")}
                theme="gray"
            />
            <Footer />
        </div>
    }
}
