import React from "react";
import { Header, Footer, ProduceBanner } from "../../../home/smart-views";
import styles from "./index.module.css";
import { CoreServices, TextLeft, TextRight, TextMiddle } from "../../section-template";

const SERVICES = [
    { title: "强化过程管控力度", icon: require("../../../../static/images/smart/customer-service/core1.png") },
    { title: "提高问题解决效率", icon: require("../../../../static/images/smart/customer-service/core2.png") },
    { title: "减少居民服务纠纷", icon: require("../../../../static/images/smart/customer-service/core3.png") },
    { title: "加强居民对于物业的了解程度", icon: require("../../../../static/images/smart/customer-service/core4.png") },
    { title: "有效提升居民满意度", icon: require("../../../../static/images/smart/customer-service/core5.png") },
    { title: "节约企业服务管理成本", icon: require("../../../../static/images/smart/customer-service/core6.png") },
];

export default class extends React.Component {
    public render() {
        return <div className={styles.container}>
            <Header theme="white" />
            <ProduceBanner />
            <CoreServices
                title="设备设施应用"
                description="设备维保、巡检、全生命周期管理保障设备健康运行"
                services={SERVICES}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/facility-management/shebeidanganguanli-icon.png")}
                title="设备档案管理"
                description="有效管理设备的品牌、厂商、型号、所在位置等档案信息，设备资产管理人员可以随时通过设备档案清晰掌握设备设施的情况"
                img={require("../../../../static/images/smart/facility-management/shebeidanganguanli.png")}
                theme="blue"
            />
            <TextLeft
                icon={require("../../../../static/images/smart/facility-management/zhinengshebeibiaoqian-icon.png")}
                title="智能设备标签"
                description="使用智能电子标签关联记录设备设施信息，设备巡检员通过智社区App扫描电子标签了解设备巡检维护记录等详情信息"
                img={require("../../../../static/images/smart/facility-management/zhinengshebeibiaoqian.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/facility-management/shebeixunjianmuban-icon.png")}
                title="设备巡检模版"
                description="通过巡检工作模板为设备的巡检工作提供细致化的工作项要求，通过灵活设置的巡检规范，对设备巡检工作进行规范化管理"
                img={require("../../../../static/images/smart/facility-management/shebeixunjianmuban.png")}
                theme="gray"
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/facility-management/xunjianjihuaguanli-icon.png")}
                title="巡检计划管理"
                description="支持按月按周的周期性巡检与临时巡检，针对不同的设备与巡检人员灵活的安排巡检计划，保证设备的安全与稳定"
                img={require("../../../../static/images/smart/facility-management/xunjianjihuaguanli.png")}
            />
            <TextMiddle
                icon={require("../../../../static/images/smart/facility-management/xunjiandaibantixing-icon.png")}
                title="巡检待办提醒"
                description="巡检计划任务集中安排，按期下发，自动提醒。 巡检人员对巡检工作任务及目标一目了然，更高效的进行工作分派与处理"
                img={require("../../../../static/images/smart/facility-management/xunjiandaibantixing.png")}
                theme="gray"
            />
            <TextLeft
                icon={require("../../../../static/images/smart/facility-management/xunjianzhixinggenzong-icon.png")}
                title="巡检执行跟踪"
                description="巡检人员通过智社区App移动化完成规范化巡检，在每处巡检点通过“扫码-拍照-记录”形成真实可追溯的完备工作记录"
                img={require("../../../../static/images/smart/facility-management/xunjianzhixinggenzong.png")}
            />
            <Footer />
        </div>
    }
}
