import React from "react";
import styles from "./index.module.css";

export default (props: { contractMe() }) =>
    <div className={styles.container}>
        <div className={styles.content}>
            <div className={styles.bannerText}>
                <div className={styles.textLine}>
                    <div className={styles.text}>
                        <span className={styles.title}>Saas云服务</span>
                        <span className={styles.prompt}>无需维护、安全高效</span>
                    </div>
                    <a href="#learn-more"><input type="button" value="了解更多" className={styles.button} /></a>
                </div>
                <div className={styles.textLine}>
                    <div className={styles.text}>
                        <span className={styles.title}>专业集成服务</span>
                        <span className={styles.prompt}>统一平台、集中管理</span>
                    </div>
                    <input type="button" value="联系我们" className={styles.button} onClick={props.contractMe} />
                </div>
            </div>
        </div>
    </div>;
