import React from "react";
import styles from "./section-template.module.css";

class CoreServices extends React.Component<{ title: string, description: string, services: any }, {}> {
    public render() {
        let { title, description, services } = this.props;
        return <div style={{ width: '100%', backgroundColor: '#fff', padding: '100px 0 130px' }}>
            <div style={{ width: '1200px', margin: '0 auto' }}>
                <div style={{ fontSize: '30px', lineHeight: '30px', color: '#000', fontWeight: 300, textAlign: 'center' }}>{title}</div>
                <div style={{ fontSize: '16px', lineHeight: '16px', color: '#666', textAlign: 'center', margin: '30px auto' }}>{description}</div>
                <div style={{ width: '80px', height: '5px', backgroundColor: '#42A1F4', borderRadius: '4px', margin: '0 auto' }}></div>
                <div style={{ fontSize: '26px', lineHeight: '26px', color: '#333', textAlign: 'center', margin: '30px auto 70px' }}>{`${title}六大核心价值`}</div>
                <div style={{ marginLeft: '250px' }}>
                    {services.map((service, idx) => {
                        return <div style={{ display: 'inline-block', textAlign: 'left', width: '50%', marginBottom: '70px' }} key={idx}>
                            <img src={service.icon} style={{ width: '40px', height: '40px', marginRight: '30px' }} />
                            <span style={{ fontSize: '20px', lineHeight: '20px', color: '#2583D5', verticalAlign: 'middle' }}>{service.title}</span>
                        </div>;
                    })}
                </div>
            </div>
        </div>
    }
}

class TextLeft extends React.Component<{ title: string, description: string, theme?: any, icon: any, img: any }, {}> {
    public render() {
        let { theme, icon, img, title, description } = this.props;

        return <div style={{ width: '100%', paddingTop: '90px', backgroundColor: theme === 'gray' ? '#F5F6F7' : '#fff' }} >
            <div style={{ width: '1200px', margin: '0 auto' }}>
                <div style={{ width: '300px', display: 'inline-block', marginLeft: '100px' }}>
                    <div style={{ marginBottom: '30px', color: (theme === 'white' || theme === 'gray') ? '#222' : '' }}>
                        <img src={icon} style={{ marginRight: '20px' }} />
                        <span style={{ fontSize: '26px', lineHeight: '26px', verticalAlign: 'middle', color: '#000', fontWeight: 300 }}>{title}</span>
                    </div>
                    <div style={{ fontSize: '16px', lineHeight: '30px', color: (theme === 'white' || theme === 'gray') ? '#333' : '' }}>{description}</div>
                </div>
                <div style={{ display: 'inline-block' }}>
                    <img src={img} />
                </div>
            </div>
        </div>
    }
}

class TextRight extends React.Component<{ title: string, description: string, theme?: any, icon: any, img: any }, {}> {
    public render() {
        let { theme, icon, img, title, description } = this.props;
        return <div style={{ width: '100%', paddingTop: '90px', backgroundColor: theme === 'white' ? '#fff' : '#F5F6F7' }} >
            <div style={{ width: '1200px', margin: '0 auto' }}>
                <div style={{ display: 'inline-block' }}>
                    <img src={img} style={{ marginLeft: '100px' }} />
                </div>
                <div style={{ width: '300px', display: 'inline-block', marginLeft: '50px' }}>
                    <div style={{ marginBottom: '30px', color: (theme === 'white' || theme === 'gray') ? '#222' : '' }}>
                        <img src={icon} style={{ marginRight: '20px' }} />
                        <span style={{ fontSize: '26px', lineHeight: '26px', verticalAlign: 'middle', color: '#000', fontWeight: 300 }}>{title}</span>
                    </div>
                    <div style={{ fontSize: '16px', lineHeight: '30px', color: (theme === 'white' || theme === 'gray') ? '#333' : '' }}>{description}</div>
                </div>
            </div>
        </div>
    }
}

class TextMiddle extends React.Component<{ title: string, description: string, theme?: any, icon: any, img: any }, {}> {
    public render() {
        let { icon, theme, title, description, img } = this.props;
        return <div style={{ width: '100%', paddingTop: '70px' }} className={styles[theme]}>
            <div className={styles.section} style={{ width: '1200px' }}>
                <div className={styles['section-title']}>
                    <img src={icon} />
                    <span>{title}</span>
                </div>
                <div className={styles['section-description']}>{description}</div>
                <div className={styles['section-img']}>
                    <img src={img} />
                </div>
            </div>
        </div>
    }
}

export {
    CoreServices,
    TextLeft,
    TextRight,
    TextMiddle,
};

